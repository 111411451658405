"use client";

import {createContext, ReactNode, useCallback, useContext, useState} from "react";

type ScrollAreaContextProps = {
    contentScrollToBottom: (key: string) => void;
    registerScrollContainer: (scrollContainer: HTMLElement) => void;
}

const ScrollAreaContext = createContext<ScrollAreaContextProps | undefined>(undefined);


export const scrollToBottom = (
    container: HTMLElement,
) => {
    if (container.children.length) {
        const lastElement = container.lastChild as HTMLElement

        lastElement?.scrollIntoView({
            behavior: 'auto',
            block: 'end',
            inline: 'nearest',
        })
    }
}

const ContentScrollProvider = ({children}: {
    children: ReactNode,
}) => {

    const [scrollAreaRegistrations, setScrollAreaRegistrations] = useState<Map<string, HTMLElement>>(new Map());

    const contentScrollToBottom = useCallback((key: string) => {
        const scrollArea = scrollAreaRegistrations.get(key);
        if (scrollArea) {
            scrollToBottom(scrollArea);
        }
    }, [scrollAreaRegistrations]);

    const registerScrollContainer = useCallback((scrollContainer: HTMLElement) => {
        setScrollAreaRegistrations(prev => {
            prev.set(scrollContainer.id, scrollContainer);
            return prev;
        });
    }, []);

    return (
        <ScrollAreaContext.Provider value={{
            contentScrollToBottom,
            registerScrollContainer,
        }}>
            {children}
        </ScrollAreaContext.Provider>
    );
}


const useContentScroll = () => {
    const context = useContext(ScrollAreaContext);
    if (!context) {
        throw new Error("useScrollArea must be used within a ScrollAreaProvider");
    }
    return context;
}

export {ContentScrollProvider, useContentScroll};
