"use client";

import React, {createContext, ReactNode, useCallback, useContext, useEffect, useState} from "react";
import {useUserContext} from "@/lib/hooks/use-user-context";
import {Subscription, UserMembership} from "@/models/UserMembership";
import {getAvailableCredits, getAvailableSubscriptions, getUserMembership} from "@/app/account/subscription/actions";

interface MembershipContextProps {
    membership: UserMembership | null;
    fetchMembershipInfo: () => Promise<void>;
    availableSubscription: Subscription[];
    isFetchingMembership: boolean;
    availableCredits: number;
    isUnlimited: boolean;
    isInTrial: boolean;
}

const MembershipContext = createContext<MembershipContextProps | undefined>(undefined);

const MembershipContextProvider = ({children}: { children: ReactNode }) => {
    const {user} = useUserContext();

    const [membership, setMembership] = useState<null | UserMembership>(null);
    const [isFetchingMembership, setIsFetchingMembership] = useState(false);
    const [availableCredits, setAvailableCredits] = useState(0);
    const [availableSubscription, setAvailableSubscription] = useState<Subscription[]>([])

    const fetchMembershipInfo = useCallback(async () => {
        if (!user) throw new Error("User is unauthorized");
        setIsFetchingMembership(true);
        const availableCredits = await getAvailableCredits();
        if (availableCredits) {
            setAvailableCredits(availableCredits);
        }
        const availableSubscriptions = await getAvailableSubscriptions();
        setAvailableSubscription(availableSubscriptions);
        // const res = await fetch(`/api/membership/${uid}/subscription`);
        // if (res.status !== 200) {
        //     console.error("Failed to fetch user membership");
        //     return;
        // }
        // const membership = await res.json();
        const membership = await getUserMembership();
        console.log("membership", membership);
        setMembership(membership);
        setIsFetchingMembership(false);
    }, [user]);

    useEffect(() => {
        if (user) {
            fetchMembershipInfo().then();
        }
    }, [fetchMembershipInfo, user]);

    return (
        <MembershipContext.Provider value={{
            membership,
            fetchMembershipInfo,
            isFetchingMembership,
            availableCredits,
            isUnlimited: membership?.isUnlimited ?? false,
            isInTrial: membership?.isTrial ?? false,
            availableSubscription,
        }}>
            {children}
        </MembershipContext.Provider>
    );
}

const useMembershipContext = () => {
    const context = useContext(MembershipContext);
    if (!context) {
        throw new Error("useUserContext must be used within a UserContextProvider");
    }
    return context;
}

export {MembershipContextProvider, useMembershipContext};

// export function useUserContext() {
//     const [user, setUser] = useState<null | PeepsUser>(null);
//
//     useEffect(() => {
//         const unsubscribe = onAuthStateChanged(async (authUser: User) => {
//             const user = authUser
//             console.log("authUser", authUser);
//             if (user) {
//                 const {displayName, email, photoURL, uid} = user;
//                 const idToken = await user.getIdToken(true);
//                 setUser({
//                     id: uid,
//                     name: displayName,
//                     email: email,
//                     avatar: photoURL,
//                     accessToken: idToken,
//                 });
//                 // document.cookie += `idToken=${idToken}; path=/`;
//                 setIdTokenCookie(idToken);
//             } else {
//                 setUser(null);
//                 clearIdTokenCookie();
//             }
//         });
//
//         return () => unsubscribe();
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, []);
//
//     return user;
// }
